import React from "react"
import { Link } from "gatsby"
import Layout from "../components/common/layout/layout"
import { Container } from "../components/global"
import styled from "styled-components"

const PrivacyPolicy = () => (
  <Layout>
    <Container>
      <Link to="/">
        <Button>Powrót</Button>
      </Link>
      <Title>Polityka prywatności strony</Title>
      <Paragraph>
        <ol>
          1. Niniejsza polityka prywatności i plików cookies opisuje zasady
          postępowania z danymi osobowymi oraz wykorzystywania plików cookies i
          innych technologii w ramach strony internetowej
        https://rsfizjoterapia.pl</ol>
        <ol>

          2. Administratorem danych osobowych strony jest Rafał Skolmowski,
          prowadzący działalność gospodarczą pod firmą Rafał Skolmowski
          Fizjoterapia i Terapia Manualna, wpisany do Centralnej Ewidencji i
          Informacji o Działalności Gospodarczej, posługujący się numerem NIP
          5451822014. Kontakt z administratorem możliwy pod pod adresem e-mail:
        skolmowskifizjoterapia@gmail.com </ol>
        <ol>
          3. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez
          Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają
          Administratora do przetwarzania danych osobowych na podstawie przepisów
        prawa lub w celu realizacji zawartej pomiędzy stronami umowy. </ol>
        <ol>
          4. Strona realizuje funkcje pozyskiwania informacji o użytkownikach i
          ich zachowaniach w następujący sposób:
        <ul>
            <li>
              poprzez dobrowolnie wprowadzone w formularzach informacje,
        </li><li>
              poprzez gromadzenie plików “cookies”.</li></ul>
        </ol>
        <ol>
          6. Serwis zbiera informacje dobrowolnie podane przez użytkownika.
       </ol>
        <ol>
          7. Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji
          konkretnego formularza np. w celu dokonania procesu obsługi kontaktu
          informacyjnego - kontakt email.
        </ol>
        <ol>
          8. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani
          udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie
          danych osobowych.
        </ol>
        <ol>
          9. Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej,
          która je tam umieściła. Osoba ta ma również praw do modyfikacji i
          zaprzestania przetwarzania swoich danych w dowolnym momencie.
     </ol><ol>
          10. Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności
          strony internetowej, na które może wpłynąć rozwój technologii
          internetowej, ewentualne zmiany prawa w zakresie ochrony danych
          osobowych oraz rozwój strony internetowej. O wszelkich zmianach będziemy
          informować w sposób widoczny i zrozumiały.
        </ol>
        <ol>
          11. W Serwisie mogą pojawiać się linki do innych stron internetowych.
          Takie strony internetowe działają niezależnie od Serwisu i nie są w
          żaden sposób nadzorowane przez stronę internetową www.rsfizjoterapia.pl
          Strony te mogą posiadać własne polityki dotyczące prywatności oraz
        regulaminy, z którymi zalecamy się zapoznać.</ol>
      </Paragraph>
    </Container>
  </Layout>
)

const Paragraph = styled.section`
  font-size: 1.1rem;
  padding: 0;
`

const Title = styled.h1`
display: flex;
  font-size: 1.5rem;
  justify-content: center;
  text-align: center;
`

const Button = styled.button`
  
  margin: 25px 40px;
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: #217CB1;
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export default PrivacyPolicy
